import * as React from 'react'
import { useState } from 'react'

const ContactFormComponent = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [hasError, setHasError] = useState(null)
  const [isSending, setIsSending] = useState(false)

  const processForm = (e) => {
    e.preventDefault()

    setIsSending(true)

    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    data.append('message', message)

    fetch('https://api.zetatrio.nl/sendmailmarsdon/', {
      method: "POST",
      body: data,
    }).then(data => {
      if (data.status === 200) {
        setEmailSent(true)
      } else {
        setHasError(true)
      }

      setIsSending(false)
    }).catch(() => {
      setHasError(true)
      setIsSending(false)
    })
  }

  return (
    <form className="contact-form" onSubmit={processForm}>
          <h3 className="contact-form__title">Neem contact met me op.</h3>
          <div className="contact-form__row">
            <label className="contact-form__label" htmlFor="contact-name">uw naam</label>
            {emailSent ? (
              <div className="contact-form__readonly">{name}</div>
            ): (
              <input type="text" className="contact-form__input" id="contact-name" tabIndex="1" value={name} onChange={e => setName(e.target.value)} />
            )}
          </div>
          <div className="contact-form__row">
            <label className="contact-form__label" htmlFor="contact-email">uw emailadres</label>
            {emailSent ? (
              <div className="contact-form__readonly">{email}</div>
            ): (
              <input type="text" className="contact-form__input" id="contact-email" tabIndex="2" value={email} onChange={e => setEmail(e.target.value)} />
            )}
          </div>
          <div className="contact-form__row">
            <label className="contact-form__label" htmlFor="contact-message">bericht</label>
            {emailSent ? (
              <div className="contact-form__readonly">{message}</div>
            ): (
              <textarea className="contact-form__textarea" id="contact-message" tabIndex="3" rows="5" onChange={e => setMessage(e.target.value)}>
                {message}
              </textarea>
            )}
          </div>
          <div className="contact-form__submit">
            {emailSent ? (
              <span className="contact-form__result">Uw bericht is verzonden.</span>
            ) : (
              <>
                {hasError && (
                  <span className="contact-form__submit-error">
                    Er ging iets fout bij het versturen.<br />Probeer het opnieuw, of stuur een email naar bob@marsdon.nl.
                  </span>
                )}
                <button type="submit" className="contact-form__submit-button" tabIndex="4">
                  {isSending && <div className="contact-form__spinner" />}
                  verzenden
                </button>
              </>
            )}
          </div>
    </form>
  )
}

export default ContactFormComponent